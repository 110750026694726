import { ArrowBack } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { appReducerTypes } from "../utils/mockData";
import { useAppDispatch, useAppState } from "../contexts/appContext";

export const BackButton = ({ step }) => {
  const appState = useAppState();
  const dispatch = useAppDispatch();
  const { widgetColor } = appState?.partnerInfo || {};
  const bgColor = widgetColor ? widgetColor : "#3e5ea9";

  return (
    <div className="flex !mt-3 !mb-3">
      <p
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          dispatch({
            type: appReducerTypes.CHANGE_FORM_STEP,
            payload: { step },
          });
        }}
        style={{ color: bgColor }}
        className="flex items-center space-x-1 rounded-[4px] cursor-pointer"
      >
        <ArrowBack fontSize="inherit" sx={{ color: bgColor }} />
        <span className="font-medium text-small">Back</span>
      </p>
    </div>
  );
};

export const FormButton = ({ nextStep, handleClick }) => {
  const appState = useAppState();
  const dispatch = useAppDispatch();
  const { getValues } = useFormContext();
  const isFollowUp = getValues()?.isFollowUp;
  const { widgetColor } = appState?.partnerInfo || {};
  const bgColor = widgetColor ? widgetColor : "#3e5ea9";
  const hasFollowUpFeature = (appState || {})?.providerFeatures
    ?.hasFollowUpFeature;
    const isHospitalConsultation = appState?.isHospitalConsultation;

  return (
    <div className="btn-block">
      <button
        onClick={async (e) => {
          try {
            handleClick && handleClick();
            e.preventDefault();
            if (!nextStep) return;
            if (hasFollowUpFeature && isFollowUp === undefined && !isHospitalConsultation) return;
            dispatch({
              type: appReducerTypes.CHANGE_FORM_STEP,
              payload: { step: nextStep },
            });
          } catch (error) {
            console.error("error from form button:", error);
          }
        }}
        style={{ backgroundColor: bgColor }}
      >
        Next
      </button>
    </div>
  );
};

export const SubmitButton = ({ loading }) => {
  const appState = useAppState();
  const { widgetColor } = appState?.partnerInfo || {};
  const cursor = loading ? "not-allowed" : "pointer";

  return (
    <button
      type="submit"
      formNoValidate="formnovalidate"
      disabled={loading}
      style={{ backgroundColor: widgetColor, cursor }}
    >
      {loading ? (
        <CircularProgress style={{ width: 20, height: 20, color: "#fff" }} />
      ) : (
        "Submit"
      )}
    </button>
  );
};
