import React from "react";
import { InputContainer } from "../../InputContainer";
import { useAppState } from "../../../contexts/appContext";
import { useFormContext } from "react-hook-form";

export const DOB = () => {
  const appState = useAppState();
  const { widgetColor } = appState?.partnerInfo || {};

  const {
    getValues,
    register,
    formState: { errors },
  } = useFormContext();

  const isDependent = getValues()?.consultationOwner === "Dependant";

  return (
    <InputContainer
      label={isDependent ? "Dependant's Date of Birth" : "Date of Birth"}
      error={errors["dob"]?.message}
      required
    >
      <input
        type="date"
        className="w-full h-[45px] bg-white font-light !text-[12px] text-[#6c6c6c] !px-[11px] rounded-[5px] border border-[rgba(128,128,128,0.25)]"
        style={{
          outlineColor: widgetColor,
        }}
        {...register("dob")}
        placeholder={
          isDependent
            ? "Enter Dependant's Date of Birth"
            : "Enter Date of Birth"
        }
      />
    </InputContainer>
  );
};
