import React from "react";
import { Select } from "./generic/Select";

export const ContactMedium = () => {
  return (
    <Select
      name="contactMedium"
      label="How would you like to contact your Doctor?"
      placeholder="Select contact medium"
      options={[
        { key: "chat", value: "Chat" },
        { key: "voice", value: "Voice" },
        { key: "video", value: "Video" },
      ]}
      isRequired
    />
  );
};
