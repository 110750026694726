import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Rating } from "@mui/material";
import { formatDistance } from "date-fns";
import { isDateValid } from "../../utils/funcs";

export const PreviousConsultationCard = ({
  widgetColor = "#3e5ea9",
  active = false,
  setActive,
  prevConsultationInfo,
}) => {
  const borderColor = active ? widgetColor : "#80808040";
  const doctor = prevConsultationInfo?.doctorData || {};
  const isDoctor = doctor?.firstName || doctor?.lastName;
  const doctorName = isDoctor ? `Dr. ${doctor?.firstName}` : "No name";
  const specialization = doctor?.specialization || "No specialization";
  const rating = doctor?.rating;
  const doctorImgUrl = doctor?.picture || "";
  const symptomsArr = (prevConsultationInfo?.symptoms || []).map(
    (symptomsObj) => symptomsObj?.name
  );
  const symptoms =
    symptomsArr?.length > 0 ? symptomsArr?.join(", ") : "No symptoms";
  const date = new Date(prevConsultationInfo?.updatedAt);
  const timeDistance = !isDateValid(date)
    ? ""
    : formatDistance(new Date(prevConsultationInfo?.updatedAt), Date.now());

  return (
    <div
      style={{ borderColor }}
      onClick={setActive}
      className={classNames(
        "flex border hover:border-[#808080] rounded-lg space-x-7 !p-4 cursor-pointer",
        { "border-2": active }
      )}
    >
      <div className="flex items-center space-x-2">
        <div className=" h-12 w-12 bg-gray-200 rounded-full">
          <img
            src={doctorImgUrl}
            alt={`${doctorName}`}
            className="h-12 w-12 rounded-full"
          />
        </div>
        <div>
          <p className="font-medium !text-base">{doctorName}</p>
          <p className="text-sm !m-0">{specialization}</p>
          {rating ? (
            <Rating
              size="small"
              defaultValue={rating || 0}
              precision={0.1}
              readOnly
            />
          ) : (
            <p>No Rating Yet!</p>
          )}
        </div>
      </div>
      <div className="space-y-2">
        <p className="flex items-center space-x-1">
          <span className="font-medium text-base">
            {symptomsArr?.length > 1 ? "Symptoms" : "Symptom"}:{" "}
          </span>
          <span className="text-sm">{symptoms}</span>
        </p>
        <p className="!m-0">{timeDistance} ago.</p>
      </div>
    </div>
  );
};

PreviousConsultationCard.propTypes = {
  widgetColor: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  setActive: PropTypes.func.isRequired,
  prevConsultationInfo: PropTypes.object.isRequired,
};
