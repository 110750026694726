import React, { useState } from "react";
import { Loading } from "../Loading";
import { BackButton } from "../buttons";
import { NoData } from "../cards/NoData";
import { useQuery } from "@tanstack/react-query";
import { DoctorCard } from "../cards/DoctorCard";
import { useFormContext } from "react-hook-form";
import { useFetchAPI } from "../../api/axios/axios";
import { useAppDispatch, useAppState } from "../../contexts/appContext";
import { useFormDispatch, useFormState } from "../../contexts/formContext";
import {
  appReducerTypes,
  formReducerTypes,
  hospitalSteps,
} from "../../utils/mockData";
import { format } from "date-fns";

export const SelectHospitalDoctor = () => {
  const appState = useAppState();
  const formState = useFormState();
  const dispatch = useAppDispatch();
  const formDispatch = useFormDispatch();
  const { GET_HOSPITAL_DOCTORS } = useFetchAPI();
  const hospitalName = formState?.selectedHospital?.name;
  const hospitalId = formState?.selectedHospital?._id;
  const selectedDocId = formState?.selectedDoctor?._id;
  const [date, setDate] = useState(formState?.selectedDate);
  const { widgetColor, apiKey } = appState?.partnerInfo || {};

  const { data, error, isLoading } = useQuery({
    queryKey: ["hospitalDoctors", hospitalId, apiKey, date],
    queryFn: async () => await GET_HOSPITAL_DOCTORS(hospitalId, date),
    enabled: !!hospitalId && !!date,
  });
  const doctors = data?.data?.data || [];

  const { setValue } = useFormContext();

  if (error)
    return (
      <NoData
        widgetColor={widgetColor}
        message="Error:"
        info={error?.message}
      />
    );

  return (
    <div>
      <form className="max-w-[570px]">
        <BackButton step={hospitalSteps.SELECT_HOSPITAL} />
        <div>
          <div className="space-y-7">
            <div>
              <h4 className="text-xl text-gray-400">
                Doctors in {hospitalName}
              </h4>
              <p>Select a Doctor to Continue</p>
            </div>
            <div className="space-y-5 !mb-5">
              <div className="!mx-1">
                <input
                  type="date"
                  value={date}
                  min={format(new Date(), "yyyy-MM-dd")}
                  disabled={isLoading}
                  className="block !h-[45px] w-full border rounded-md bg-white !px-4"
                  onChange={(e) => {
                    const value = e.target.value;
                    setDate(value)
                    formDispatch({
                      type: formReducerTypes.ADD_SELECTED_DOCTOR,
                      payload: {
                        date: value,
                      },
                    });
                  }}
                />
              </div>
              {isLoading ? (
                <Loading color={widgetColor} size={20} />
              ) : doctors.length < 1 ? (
                <NoData
                  widgetColor={widgetColor}
                  message="No Doctor available"
                  info=""
                />
              ) : (
                doctors.map((doctor, idx) => {
                  return (
                    <DoctorCard
                      key={idx}
                      doctor={doctor}
                      active={doctor?._id === selectedDocId}
                      widgetColor={widgetColor}
                      setActive={() => {
                        setValue("doctor", doctor?._id);
                        formDispatch({
                          type: formReducerTypes.ADD_SELECTED_DOCTOR,
                          payload: {
                            doctor: doctor,
                          },
                        });
                        dispatch({
                          type: appReducerTypes?.CHANGE_FORM_STEP,
                          payload: {
                            step: hospitalSteps.CREATE,
                          },
                        });
                      }}
                    />
                  );
                })
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
