import React from "react";
import { hospitalSteps, steps } from "../../../utils/mockData";
import { InputContainer } from "../../InputContainer";
import { useAppState } from "../../../contexts/appContext";
import { useFormContext } from "react-hook-form";

export const ConsultationType = ({setNextStep}) => {
  const appState = useAppState();
  const { widgetColor } = appState?.partnerInfo || {};
  const { setValue, getValues } = useFormContext();

  return (
    <InputContainer
      label="Consultation Type"
      info="Please, select your preferred type."
    >
      <div className="space-y-1 pt-5">
        <label className="flex items-center space-x-2" htmlFor="new">
          <input
            type="radio"
            style={{
              color: widgetColor,
              accentColor: widgetColor,
            }}
            className="w-4 h-4"
            id="new"
            value={false}
            checked={getValues()?.isFollowUp === false}
            onChange={() => {
              setValue("isFollowUp", false);
              const isHospitalConsultation = appState?.isHospitalConsultation;
              const nextStep = isHospitalConsultation ? hospitalSteps.SELECT_HOSPITAL : steps.CREATE;
              setNextStep(nextStep);
            }}
          />
          <span>New consultation</span>
        </label>
        <label className="flex items-center space-x-2" htmlFor="follow-up">
          <input
            type="radio"
            style={{
              color: widgetColor,
              accentColor: widgetColor,
            }}
            className="w-4 h-4"
            id="follow-up"
            name="type"
            value={true}
            checked={getValues()?.isFollowUp === true}
            onChange={() => {
              setValue("isFollowUp", true);
              setNextStep(steps.SELECT_DOCTOR);
            }}
          />
          <span>Follow-up consultation</span>
        </label>
      </div>
    </InputContainer>
  );
};
