import React from "react";
import { useFormContext } from "react-hook-form";
import { InputContainer } from "../../InputContainer";
import { useAppState } from "../../../contexts/appContext";

export const AdditionalInformation = () => {
  const appState = useAppState();
  const { widgetColor } = appState?.partnerInfo || {};

  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <InputContainer
      label="Additional Information (Optional)"
      error={errors["description"]?.message}
    >
      <textarea
        style={{ width: "100%", outlineColor: widgetColor }}
        {...register("description")}
        rows={5}
        placeholder="Enter additional information"
      ></textarea>
    </InputContainer>
  );
};
