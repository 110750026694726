import React, { useState } from "react";
import { useFetchAPI } from "../../api/axios/axios";
import { useAppState } from "../../contexts/appContext";
import { useQuery as useReactQuery } from "@tanstack/react-query";
import { ReactComponent as Success } from "../../assets/svgs/success.svg";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

export const GetEnrolleeId = ({ open, close }) => {
  const appState = useAppState();
  const [value, setValue] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(false);
  const { providerId, widgetColor } = appState?.partnerInfo || {};
  const { GET_ENROLLEE_ID } = useFetchAPI();

  const {
    status,
    isLoading,
    error,
  } = useReactQuery({
    queryKey: ["enrolleeID", phoneNumber],
    queryFn: async () => await GET_ENROLLEE_ID(providerId, value),
    enabled: !!phoneNumber,
  });


  return (
    <Dialog open={open} onClose={close} className="dialogV" maxWidth="xs">
      {status === "success" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "16%",
            textAlign: "center",
            flexDirection: "column",
          }}
        >
          <Success />
          <p style={{ marginTop: "5%" }}>
            Your Enrollee/Staff ID has been sent to your phone number.
          </p>
        </div>
      ) : (
        <div>
          <DialogTitle>Retrieve your enrollee ID</DialogTitle>
          <p
            className="infoText"
            style={{
              width: "90%",
              marginLeft: "7%",
              marginTop: "-4%",
              marginBottom: "-2%",
            }}
          >
            Enter your phone number and we will send your enrollee ID/policy
            number to you.
          </p>
          <DialogContent>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "3% 0%",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              {error && (
                <p className="text-[10px] font-medium !text-red-500">
                  Phone number does not exist.
                </p>
              )}
              <input
                required
                type="number"
                name="name"
                id="myID"
                style={{
                  width: "100%",
                  height: "45px",
                  paddingLeft: "11px",
                  borderRadius: "5px",
                  border: "1px solid rgba(128, 128, 128, 0.25)",
                  background: "#fff",
                  fontSize: "12px",
                  color: "#6c6c6c",
                  fontWeight: 300,
                  marginLeft: "2px",
                }}
                placeholder="Enter your phone number"
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "0% 1.5rem 8%",
            }}
          >
            <Button
              onClick={() => setPhoneNumber(value)}
              style={{ width: "100%" }}
              variant="contained"
              sx={{
                background: widgetColor,
                "&:hover": {
                  backgroundColor: widgetColor,
                },
              }}
            >
              {isLoading ? (
                <CircularProgress
                  style={{ width: 20, height: 20, color: "#fff" }}
                />
              ) : (
                "Submit"
              )}
            </Button>
          </DialogActions>
        </div>
      )}
    </Dialog>
  );
};
