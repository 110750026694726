import React from "react";
import { Select } from "./generic/Select";

export const DiscomfortLevel = () => {
  return (
    <Select
      name="discomfortLevel"
      label="Discomfort Level"
      placeholder="Select discomfort level"
      options={[
        { value: "None", key: "none" },
        { value: "Mild", key: "mild" },
        { value: "Moderate", key: "moderate" },
        { value: "Severe", key: "severe" },
        { value: "Intense", key: "intense" },
      ]}
      isRequired
    />
  );
};
