import React from "react";

export const ErrorSvg = ({color = "#3e5ea9"}) => {

    return (
        <svg width="204" height="140" viewBox="0 0 204 162" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.75" width="202.5" height="162" fill="transparent"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M51.78 108.54H125.49C125.907 108.54 126.314 108.495 126.705 108.409C127.096 108.495 127.503 108.54 127.92 108.54H170.04C173.171 108.54 175.71 106.001 175.71 102.87C175.71 99.7384 173.171 97.1998 170.04 97.1998H165.18C162.049 97.1998 159.51 94.6613 159.51 91.5298C159.51 88.3984 162.049 85.8598 165.18 85.8598H180.57C183.701 85.8598 186.24 83.3213 186.24 80.1898C186.24 77.0584 183.701 74.5198 180.57 74.5198H162.75C165.881 74.5198 168.42 71.9813 168.42 68.8498C168.42 65.7184 165.881 63.1798 162.75 63.1798H110.91C114.041 63.1798 116.58 60.6413 116.58 57.5098C116.58 54.3784 114.041 51.8398 110.91 51.8398H64.74C61.6086 51.8398 59.07 54.3784 59.07 57.5098C59.07 60.6413 61.6086 63.1798 64.74 63.1798H32.34C29.2086 63.1798 26.67 65.7184 26.67 68.8498C26.67 71.9813 29.2086 74.5198 32.34 74.5198H52.59C55.7215 74.5198 58.26 77.0584 58.26 80.1898C58.26 83.3213 55.7215 85.8598 52.59 85.8598H20.19C17.0586 85.8598 14.52 88.3984 14.52 91.5298C14.52 94.6613 17.0586 97.1998 20.19 97.1998H51.78C48.6486 97.1998 46.11 99.7384 46.11 102.87C46.11 106.001 48.6486 108.54 51.78 108.54ZM183.81 108.54C186.941 108.54 189.48 106.001 189.48 102.87C189.48 99.7386 186.941 97.2 183.81 97.2C180.679 97.2 178.14 99.7386 178.14 102.87C178.14 106.001 180.679 108.54 183.81 108.54Z" fillOpacity={0.2} fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M75.27 113.398C65.4282 113.398 57.45 105.601 57.45 95.983C57.45 86.3649 65.4282 78.568 75.27 78.568C75.6885 78.568 76.1036 78.5821 76.5149 78.6098C76.2291 77.0191 76.08 75.3809 76.08 73.708C76.08 58.498 88.41 46.168 103.62 46.168C115.763 46.168 126.07 54.0266 129.733 64.9353C130.601 64.8445 131.483 64.798 132.375 64.798C146.019 64.798 157.08 75.6774 157.08 89.098C157.08 101.848 147.097 112.367 134.4 113.38V113.398H88.6413H75.27ZM84.9867 113.398H79.3454H84.9867Z" fill="white"/>
            <path d="M84.9867 113.398H79.3454M75.27 113.398C65.4282 113.398 57.45 105.601 57.45 95.983C57.45 86.3649 65.4282 78.568 75.27 78.568C75.6885 78.568 76.1036 78.5821 76.5149 78.6098C76.2291 77.0191 76.08 75.3809 76.08 73.708C76.08 58.498 88.41 46.168 103.62 46.168C115.763 46.168 126.07 54.0266 129.733 64.9353C130.601 64.8445 131.483 64.798 132.375 64.798C146.019 64.798 157.08 75.6774 157.08 89.098C157.08 101.848 147.097 112.367 134.4 113.38V113.398H88.6413H75.27Z" stroke={color} strokeWidth="2.025" strokeLinecap="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M95.2058 52.1188C95.2058 78.2195 118.712 99.7268 148.98 102.631C145.187 107.295 139.329 110.459 132.656 110.956V110.971H78.3017C71.7069 110.971 60.6898 108.193 60.6898 96.0254C60.6898 83.8581 69.2549 81.0796 78.3017 81.0796C78.6864 81.0796 79.068 81.0917 79.4461 81.1155C79.1834 79.7503 79.1372 78.3432 79.0463 76.9086C78.4319 67.2184 82.6594 55.0059 95.2204 51.0312C95.2104 51.3936 95.2058 51.7557 95.2058 52.1188ZM103.714 92.9552C101.253 92.9552 99.2574 94.9269 99.2574 97.3591C99.2574 99.7913 101.253 101.763 103.714 101.763C106.175 101.763 108.171 99.7913 108.171 97.3591C108.171 94.9269 106.175 92.9552 103.714 92.9552Z" fill={color} fillOpacity={0.2}/>
            <path d="M104.025 102.058C106.486 102.058 108.48 100.064 108.48 97.6034C108.48 95.143 106.486 93.1484 104.025 93.1484C101.565 93.1484 99.5703 95.143 99.5703 97.6034C99.5703 100.064 101.565 102.058 104.025 102.058Z" stroke={color} strokeWidth="2.025"/>
            <path d="M91.4704 88.2906L97.1404 83.8412L91.4704 79.6055" stroke={color} strokeWidth="2.025" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M116.58 88.2906L110.91 83.8412L116.58 79.6055" stroke={color} strokeWidth="2.025" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M111.72 54.2695C116.993 55.6648 121.155 59.7879 122.607 65.037" stroke={color} strokeWidth="2.025" strokeLinecap="round"/>
            <path d="M128.73 40.5006C130.072 40.5006 131.16 39.4127 131.16 38.0706C131.16 36.7286 130.072 35.6406 128.73 35.6406C127.388 35.6406 126.3 36.7286 126.3 38.0706C126.3 39.4127 127.388 40.5006 128.73 40.5006Z" stroke={color} strokeWidth="1.62"/>
            <path d="M153.84 53.4616C155.182 53.4616 156.27 52.3736 156.27 51.0316C156.27 49.6895 155.182 48.6016 153.84 48.6016C152.498 48.6016 151.41 49.6895 151.41 51.0316C151.41 52.3736 152.498 53.4616 153.84 53.4616Z" fill={color}/>
            <path d="M135.013 46.7852L141.783 53.5553M141.886 46.7852L135.116 53.5553L141.886 46.7852Z" stroke={color} strokeWidth="2.025" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M58.5872 61.2305L63.4472 66.0905M63.4472 61.2305L58.5872 66.0905L63.4472 61.2305Z" stroke={color} strokeWidth="2.025" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M69.6 55.8913C70.9421 55.8913 72.03 54.8033 72.03 53.4613C72.03 52.1192 70.9421 51.0312 69.6 51.0312C68.258 51.0312 67.17 52.1192 67.17 53.4613C67.17 54.8033 68.258 55.8913 69.6 55.8913Z" fill={color}/>
        </svg>
    )
}