import React from "react";

export const InputContainer = ({ label, info, required, children, error }) => {
  return (
    <div className="my-5">
      <label className="label block">
        {label}
        {required && (
          <sup className="text-red-500 text-base relative -top-[2px] left-1">
            *
          </sup>
        )}
      </label>
      {info && <p className="infoText">{info}</p>}
      <div className="">{children}</div>
      {error && <p className="text-sm !text-red-500">{error}</p>}
    </div>
  );
};
