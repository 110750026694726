import React from "react";
import { Select } from "./generic/Select";

export const Gender = () => {
  return (
    <Select
      name="gender"
      label="Gender"
      placeholder="Select a gender"
      options={[
        { key: "male", value: "Male" },
        { value: "Female", key: "female" },
      ]}
      isRequired
    />
  );
};
