import React, { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { InputContainer } from "../../InputContainer";
import { useAppState } from "../../../contexts/appContext";
import { ArrowDropDown } from "@mui/icons-material";

const deliveryFeatureWhiteList = (
  process.env.REACT_APP_DELIVERY_WHITELIST || ""
).split(" ");

const nemHMO = (process.env.REACT_APP_NEM_HMO || "").split(" ");

const defaultPharmacy = {
  pharmacyName: "--Select Pharmacy--",
  pharmacyCode: null,
  address: null,
  area: null,
  lga: null,
  state: null,
};

export const PharmacyAndDeliverySelection = ({ loading, pharmacyArray }) => {
  const appState = useAppState();
  const [isOpen, setIsOpen] = useState(false);
  const { widgetColor, providerId } = appState?.partnerInfo || {};

  const pharmacyArr = useMemo(
    () => [defaultPharmacy, ...pharmacyArray],
    [pharmacyArray]
  );

  const {
    register,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  const isNemHMO = nemHMO.includes(providerId);
  const canSelectDeliveryMode = deliveryFeatureWhiteList.includes(providerId);

  const watchPharmacyCode = watch("pharmacyCode");

  useEffect(() => {
    const pharmacyCode = getValues()?.pharmacyCode;
    const noPharmacySelected = !pharmacyCode || pharmacyCode === "";

    const foundPharmacy = (pharmacyArr || []).filter(
      (pharmacy) => pharmacyCode === pharmacy?.pharmacyCode
    )[0];

    const pharmName = noPharmacySelected ? null : foundPharmacy?.pharmacyName;
    setValue("pharmacyName", pharmName);
    const { address, area, lga, state } = foundPharmacy || {};
    const pharmacyAddress = noPharmacySelected
      ? null
      : `${address}, ${area}, ${lga}, ${state}.`;
    setValue("pharmacyAddress", pharmacyAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchPharmacyCode]);

  useEffect(() => {
    if (!canSelectDeliveryMode) {
      setValue("isDelivery", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canSelectDeliveryMode]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (pharmacyCode) => {
    setValue("pharmacyCode", pharmacyCode);
    setIsOpen(false);
  };

  const selectedPharmacyCode = getValues()?.pharmacyCode;

  const selectedOption = useMemo(() => {
    const filteredPharmacyArr = (pharmacyArr || []).filter(
      (pharm) => pharm?.pharmacyCode === selectedPharmacyCode
    );

    return filteredPharmacyArr?.length > 0
      ? filteredPharmacyArr[0]
      : defaultPharmacy;
  }, [pharmacyArr, selectedPharmacyCode]);

  // console.log(selectedOption);

  return (
    <div>
      {isNemHMO && (
        <p className="max-w-[450px] bg-yellow-50 !text-yellow-800 border border-yellow-500 rounded-md !p-2 !mt-5">
          <b>PS:</b> Your chosen pharmacy cannot be changed. <br /> However,
          orders can only be reroute based on medication unavailability at your
          preferred pharmacy.
        </p>
      )}
      <InputContainer
        label="Select Your Preferred Pickup Pharmacy"
        info="Selecting a pharmacy closest to you will help us process your drugs faster."
        error={errors["pharmacyCode"]?.message}
        required
      >
        <div className="relative inline-block w-full">
          {/* Dropdown button */}
          <button
            type="button"
            className="w-full h-[45px] flex justify-between items-center !text-[#6c6c6c] !text-xs !font-light !bg-white rounded text-left !px-3 !py-2 !m-0"
            onClick={toggleDropdown}
            style={{
              border: "1px solid #80808040",
            }}
          >
            <span>{loading ? "Loading..." : selectedOption?.pharmacyName}</span>
            <span>
              <ArrowDropDown />
            </span>
          </button>

          {/* Dropdown menu */}
          {isOpen && (
            <div className="max-h-[200px] absolute z-10 !mt-2 w-full bg-white border rounded shadow-lg overflow-y-auto">
              {([defaultPharmacy, ...pharmacyArray] || []).map((pharmacy) => {
                const pharmName = pharmacy?.pharmacyName
                  ? pharmacy?.pharmacyName
                  : "No name";
                const pharmAddress = pharmacy?.address
                  ? `${pharmacy?.address}, `
                  : "";

                const pharmArea = pharmacy?.area ? `${pharmacy?.area}, ` : "";
                const pharmLGA = pharmacy?.lga ? `${pharmacy?.lga}, ` : "";
                const pharmState = pharmacy?.state
                  ? `${pharmacy?.state} state.`
                  : "";
                const pharmCode = pharmacy?.pharmacyCode;
                const isSelected = pharmCode === getValues()?.pharmacyCode;

                return (
                  <div
                    key={pharmCode}
                    className="!px-3 !py-2 border-b cursor-pointer hover:bg-gray-50"
                    onClick={() => handleOptionClick(pharmCode)}
                    style={
                      isSelected
                        ? {
                            backgroundColor: widgetColor,
                          }
                        : {}
                    }
                  >
                    <p
                      style={
                        isSelected ? { color: "#fff" } : { color: widgetColor }
                      }
                      className="text-sm font-medium"
                    >
                      {pharmName}
                    </p>
                    <p
                      style={
                        isSelected ? { color: "#fff" } : { color: "#0c1322" }
                      }
                      className="text-xs text-gr"
                    >
                      {`${pharmAddress}${pharmArea}${pharmLGA}${pharmState}`}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {/* <select
          style={{ outlineColor: widgetColor }}
          className="select-input heela-discomfort-level"
          {...register("pharmacyCode")}
        >
          <option value={undefined}>
            {loading ? "Loading..." : "Select a pharmacy"}
          </option>
          {(pharmacyArray || []).map((pharmacy) => {
            const pharmName = pharmacy?.pharmacyName
              ? pharmacy?.pharmacyName
              : "No name";
            const pharmAddress = pharmacy?.address
              ? `${pharmacy?.address}, `
              : "";
            const pharmArea = pharmacy?.area ? `${pharmacy?.area}, ` : "";
            const pharmLGA = pharmacy?.lga ? `${pharmacy?.lga}, ` : "";
            const pharmState = pharmacy?.state
              ? `${pharmacy?.state} state.`
              : "";

            return (
              <option
                key={pharmacy?.pharmacyCode}
                value={pharmacy?.pharmacyCode}
                className="block"
              >
                <span className="block">
                  <span>{pharmName}</span>
                </span>
                <br />
                <span>
                  {`${pharmAddress}${pharmArea}${pharmLGA}${pharmState}`}
                </span>
              </option>
            );
          })}
        </select> */}
      </InputContainer>
      {canSelectDeliveryMode && (
        <InputContainer
          label="Select fulfillment option"
          error={errors["isDelivery"]?.message}
        >
          <div className="space-y-1 pt-5">
            <label className="flex items-center space-x-2" htmlFor="pickup">
              <input
                type="radio"
                style={{
                  color: widgetColor,
                  accentColor: widgetColor,
                }}
                className="w-4 h-4"
                id="pickup"
                name="isDelivery"
                value={false}
                {...register("isDelivery")}
              />
              <span>Pharmacy Pickup</span>
            </label>
            <label className="flex items-center space-x-2" htmlFor="delivery">
              <input
                type="radio"
                style={{
                  color: widgetColor,
                  accentColor: widgetColor,
                }}
                className="w-4 h-4"
                id="delivery"
                name="isDelivery"
                value={true}
                {...register("isDelivery")}
              />
              <span>Home Delivery</span>
            </label>
          </div>
        </InputContainer>
      )}
    </div>
  );
};
