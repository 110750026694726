import React, { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { CircularProgress } from "@mui/material";
import { InputContainer } from "../../InputContainer";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useFetchAPI } from "../../../api/axios/axios";
// import { useMixPanel } from "../../../hooks/useMixPanel";
import { useAppState } from "../../../contexts/appContext";
import { formReducerTypes } from "../../../utils/mockData";
import { GetEnrolleeId } from "../../modals/GetEnrolleeId";
import { useFormDispatch } from "../../../contexts/formContext";
import { useQuery as useReactQuery } from "@tanstack/react-query";

const hpID = (process.env.REACT_APP_HPID || "").split(" ");
const externalSearchWhitelist = (
  process.env.REACT_APP_EXTERNAL_SEARCH_WHITELIST || ""
).split(" ");

export const EnrolleeId = ({ name, queryKey, setFoundUser }) => {
  const appState = useAppState();
  const formDispatch = useFormDispatch();
  const { GET_HMO_USER, GET_HMO_USER_EXTERNAL, fetchData } = useFetchAPI();
  const [modal, setModal] = useState(false);
  // const { checkAndTriggerFormLoaded } = useMixPanel();
  const { providerId, apiKey, widgetColor } = appState?.partnerInfo || {};
  const isExternalSearch = externalSearchWhitelist.includes(providerId);

  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext();

  const isDependentConsultation =
    `${getValues()?.consultationOwner}`.toLowerCase() === "dependant";

  const enrolleeType =
    isDependentConsultation && name === "hmoId"
      ? "dependant"
      : isDependentConsultation && name === "principalHmoId"
      ? "principal"
      : "enrollee";

  const isPrincipal = enrolleeType === "principal";
  const isDependant = enrolleeType === "dependant";
  const formHmoId = getValues()?.[name];

  const {
    data: hmoUserById,
    status,
    isLoading,
  } = useReactQuery({
    queryKey: [queryKey, formHmoId],
    queryFn: async () =>
      (await isExternalSearch)
        ? fetchData(GET_HMO_USER_EXTERNAL(providerId, formHmoId, apiKey))
        : GET_HMO_USER(providerId, formHmoId, apiKey),
    enabled: !!formHmoId,
    select: (response) => {
      if (isExternalSearch) {
        return response?.data;
      } else {
        return response?.data?.data?.[0];
      }
    },
  });

  const foundUser = useMemo(() => {
    return hmoUserById && hmoUserById?._id;
  }, [hmoUserById]);

  useEffect(() => {
    setFoundUser(foundUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hmoUserById]);

  const hmoUserObj = useMemo(() => {
    if (foundUser) {
      return hmoUserById;
    }
    return { type: "none" };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundUser]);

  useEffect(() => {
    if (isPrincipal) return;
    if (hmoUserObj?.type === "none") return;

    formDispatch({
      type: formReducerTypes.ADD_USER_DATA,
      payload: { userData: hmoUserObj },
    });

    // INIT MIX-PANEL LOGIN/SIGNUP
    // checkAndTriggerFormLoaded(hmoUserObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hmoUserObj]);

  const label = isPrincipal
    ? "Principal HMO/Access ID"
    : isDependant
    ? "Dependant HMO/Access ID"
    : "HMO/Access ID";

  const placeHolder = isPrincipal
    ? "Enter Principal's ID"
    : isDependant
    ? "Enter dependant's ID"
    : "Enter your ID";

  const enrolleeInfo =
    "This is the HMO/Access ID given to you by your provider.";
  const principalInfo = "This is the ID of the Primary holder of the HMO plan.";
  const dependentInfo = "This is the ID of a beneficiary of the HMO plan.";

  return (
    <InputContainer
      label={label}
      error={errors[name]?.message}
      info={
        isPrincipal ? principalInfo : isDependant ? dependentInfo : enrolleeInfo
      }
      required
    >
      <div className="w-full">
        <div className="flex space-x-1">
          <input
            type="text"
            style={{ outlineColor: widgetColor }}
            {...register(name, { validate: true })}
            placeholder={placeHolder}
          />
          {foundUser && !isLoading && status !== "pending" && (
            <TaskAltIcon
              fontSize="medium"
              sx={{ color: "green", marginY: "auto" }}
            />
          )}
          {isLoading && (
            <CircularProgress
              sx={{ color: widgetColor, marginY: "auto" }}
              size={25}
            />
          )}
        </div>
        {!foundUser && !isLoading && (
          <CautionText
            widgetColor={widgetColor}
            text="Can’t remember your access id,"
            linkText="Click here."
            onClick={() => setModal(true)}
          />
        )}
        {!foundUser && hpID.includes(providerId) && (
          <CautionText
            widgetColor={widgetColor}
            text="⁠⁠Don’t have an access id,"
            linkText="sign up."
            url="https://onelink.to/wqu28p"
          />
        )}
        {!foundUser && !isLoading && status !== "pending" && (
          <span className="redtext">Invalid ID</span>
        )}
      </div>
      <GetEnrolleeId open={modal} close={() => setModal(false)} />
    </InputContainer>
  );
};

export const CautionText = ({ onClick, widgetColor, text, linkText, url }) => {
  return (
    <p className="infoText">
      {text}{" "}
      {onClick && (
        <span
          onClick={(e) => {
            e.preventDefault();
            onClick();
          }}
          style={{ color: widgetColor, cursor: "pointer" }}
        >
          {linkText}
        </span>
      )}
      {url && (
        <a href={url} rel="noreferrer" target="_blank">
          <span style={{ color: widgetColor, cursor: "pointer" }}>
            {linkText}
          </span>
        </a>
      )}
    </p>
  );
};
