import React, { useEffect, useMemo, useState } from "react";
import { DOB } from "./inputs/DOB";
import { Loading } from "../Loading";
import { Time } from "./inputs/Time";
import { Email } from "./inputs/Email";
import { NoData } from "../cards/NoData";
import { Gender } from "./inputs/Gender";
import { Symptoms } from "./inputs/Symptoms";
import { AddressInput } from "./inputs/Address";
import { useFormContext } from "react-hook-form";
import { CircularProgress } from "@mui/material";
import { PhoneNumber } from "./inputs/PhoneNumber";
import { FirstNotice } from "./inputs/FirstNotice";
import { CustomInput } from "./inputs/generic/Input";
import { useFetchAPI } from "../../api/axios/axios";
import { BackButton, SubmitButton } from "../buttons";
// import { useMixPanel } from "../../hooks/useMixPanel";
import { ContactMedium } from "./inputs/ContactMedium";
import { useAppState } from "../../contexts/appContext";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useFormState } from "../../contexts/formContext";
import { DiscomfortLevel } from "./inputs/DiscomfortLevel";
import { useQuery as useReactQuery } from "@tanstack/react-query";
import { AdditionalInformation } from "./inputs/AdditionalInformation";
import { PreviousConsultationCard } from "../cards/PreviousConsultationCard";
import { GET_ISSUES, getWellaHealthPharmacies } from "../../api/graphQL/query";
import { PharmacyAndDeliverySelection } from "./inputs/PharmacyAndDeliverySelection";
import {
  checkAddress,
  checkAndFormatAddress,
  formatValues,
  getClosestPharmacies,
  getPrevStepInCreateConsultationForm,
  hideFollowUpInput,
  isObject,
  updateUserInfo,
  validateValues,
} from "../../utils/funcs";

export const NewConsultation = () => {
  const appState = useAppState();
  const appFormState = useFormState();
  // const { createdConsultation } = useMixPanel();
  const [loading, setLoading] = useState(false);
  const [conError, setConError] = useState(false);
  const [inputError, setInputError] = useState({
    emailHasError: false,
    phoneNumHasError: false,
    genderHasError: false,
    dobHasError: false,
  });
  const { widgetColor, apiKey } = appState?.partnerInfo || {};
  const { data, error } = useQuery(GET_ISSUES);
  if (error) console.error("Error from get issues", error);
  const { CREATE_CONSULTATION, UPDATE_USER_INFO, GET_DOCTOR_AVAILABILITY } =
    useFetchAPI();

  const {
    selectedPrevConsultation,
    selectedDoctor,
    selectedHospital,
    selectedDate,
  } = appFormState || {};
  const date = selectedDate;
  const doctorId = selectedDoctor?._id;

  const isHospitalConsultation = appState?.isHospitalConsultation;
  const hasPharmacyFeature = appState?.providerFeatures?.hasPharmacyFeature;
  // const hasFollowUpFeature = appState?.providerFeatures?.hasFollowUpFeature;
  const doctorName = `Dr. ${selectedDoctor?.firstName} ${selectedDoctor?.lastName}`;

  const {
    data: doctorAvailability,
    isLoading,
    error: doctorAvailabilityError,
  } = useReactQuery({
    queryKey: ["doctorAvailability", doctorId, date],
    queryFn: async () => await GET_DOCTOR_AVAILABILITY(date, doctorId),
    select: (data) => {
      const doctorAvailabilityArr = data?.data?.data?.times || [];

      return doctorAvailabilityArr
        .filter((item) => !item?.passed)
        .map((e) => ({ key: e?.start, value: `${e?.start} (GMT+1)` }));
    },
    enabled: !!doctorId && !!date,
  });

  const issues = data?.getIssues?.issues;

  const {
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
  } = useFormContext();

  const address = checkAndFormatAddress(getValues()?.address || "");

  const [getPharmacies, { data: pharmacies, loading: loadingPharmacies }] =
    useLazyQuery(getWellaHealthPharmacies);
  const pharmacyArray = pharmacies?.getPharmaciesFromWellaHealth?.data;

  const hmoUserObj = appFormState?.userData || {};

  const isAddressComplete = useMemo(() => {
    return checkAddress(address);
  }, [address]);

  const showPharmacySelectInput = useMemo(() => {
    return isAddressComplete && pharmacyArray && pharmacyArray?.length > 0;
  }, [isAddressComplete, pharmacyArray]);

  const hasDob = !!hmoUserObj?.dob;
  const hasEmail = !!hmoUserObj?.email;
  const hasPhoneNum = !!hmoUserObj?.phone;
  const hasGender = !!hmoUserObj?.gender;
  const isFollowUp = getValues("isFollowUp");
  const prevStep = getPrevStepInCreateConsultationForm(
    isFollowUp,
    isHospitalConsultation
  );

  // Display errors of hidden inputs
  useEffect(() => {
    setConError(null);
    (Object.keys(errors) || []).forEach((item) => {
      setConError(null);

      if (
        [
          "dob",
          "type",
          "gender",
          "doctor",
          "lastName",
          "symptoms",
          "firstName",
          "providerId",
          "pharmacyCode",
          "pharmacyName",
        ].includes(item)
      ) {
        setConError(errors[item]?.message);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  // Set address in address field and get closet pharmacies
  useEffect(() => {
    if (isAddressComplete) {
      getClosestPharmacies(getPharmacies, address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddressComplete]);

  useEffect(() => {
    if (Boolean(errors["email"]))
      setInputError((prev) => ({ ...prev, emailHasError: true }));
    if (Boolean(errors["phoneNumber"]))
      setInputError((prev) => ({ ...prev, phoneNumHasError: true }));
    if (Boolean(errors["gender"]))
      setInputError((prev) => ({ ...prev, genderHasError: true }));
    if (Boolean(errors["dob"]))
      setInputError((prev) => ({ ...prev, dobHasError: true }));
  }, [errors]);

  const showEmailInput =
    !hasEmail || Boolean(errors["email"]) || inputError.emailHasError;
  const showPhoneNumInput =
    !hasPhoneNum ||
    Boolean(errors["phoneNumber"]) ||
    inputError.phoneNumHasError;
  const showGenderInput =
    !hasGender || Boolean(errors["gender"]) || inputError.genderHasError;
  const showDOBInput =
    !hasDob || Boolean(errors["dob"]) || inputError.dobHasError;

  // Validate form and create consultation
  const onSubmit = async (values) => {
    try {
      setConError(false);
      setLoading(true);
      const { isValidated, error } = validateValues(
        hasPharmacyFeature,
        values,
        pharmacyArray,
        false
      );

      if (!isValidated) {
        setError(
          error?.name,
          { type: "manual", message: error?.message },
          { shouldFocus: true }
        );
        setConError("Missing Felids!");
        setLoading(false);
        return;
      }

      updateUserInfo(values, hmoUserObj, UPDATE_USER_INFO, apiKey).catch(
        (err) => console.error(err)
      );

      if (values?.time) {
        const dateString = `${selectedDate}T${values.time}:00+01:00`;
        values.time = dateString;
      }

      const formattedData = formatValues(
        values,
        pharmacyArray?.length > 0,
        isHospitalConsultation
      );

      const response = await CREATE_CONSULTATION(apiKey, formattedData);
      const responseText = await response.text();
      const parsedResponse = await JSON.parse(responseText);

      if (parsedResponse?.errors) {
        setLoading(false);
        setConError(parsedResponse.errors[0].msg);
        return;
      }

      const status = response.status;
      if (status !== 200 && status !== 201) {
        setLoading(false);
        const error =
          parsedResponse?.message ||
          parsedResponse?.errors[0]?.msg ||
          "something went wrong. Try again.";

        isObject(error)
          ? setConError(`Error: ${error?.details}`)
          : setConError(error);
        return;
      }

      if (!parsedResponse?.data) {
        setLoading(false);
        setConError(parsedResponse?.message);
      }

      // await createdConsultation(parsedResponse?.data?._id).catch((error) => {
      //   console.error("Event from createdConsultation FN failed:", error);
      // });

      //       await createdConsultation(parsedResponse?.data?._id).catch((error) => {
      //         console.error("Event from createdConsultation FN failed:", error);
      //       });

      const consultationURL = parsedResponse?.data?.url;
      window.open(consultationURL, "_self");
    } catch (error) {
      setLoading(false);
      console.error(error);
      setConError(error?.message);
    }
  };

  if (doctorAvailabilityError)
    return (
      <NoData
        widgetColor={widgetColor}
        message="Error:"
        info={
          error?.message || doctorAvailabilityError?.message /* ||
          serverTimeError?.message */
        }
      />
    );

  if (isLoading)
    return (
      <p className="text-center">
        <CircularProgress sx={{ color: widgetColor }} size={20} />
      </p>
    );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-container">
      <BackButton step={prevStep} />
      <div className="space-y-3">
        {/* SELECTED PREVIOUS CONSULTATION */}
        {isFollowUp && (
          <PreviousConsultationCard
            widgetColor={widgetColor}
            active={true}
            setActive={() => null}
            prevConsultationInfo={selectedPrevConsultation || {}}
          />
        )}

        {isHospitalConsultation && !isFollowUp && (
          <CustomInput
            type="text"
            label="Hospital"
            value={selectedHospital?.name}
            className="!bg-gray-100"
            name="hospital"
            placeholder=""
            isRequired
            disabled
          />
        )}

        {isHospitalConsultation && !isFollowUp && (
          <CustomInput
            type="text"
            label="Doctor"
            value={doctorName}
            name="doctor"
            placeholder=""
            className="!bg-gray-100"
            isRequired
            disabled
          />
        )}

        {/* TIME */}
        {isHospitalConsultation && !isFollowUp && (
          <Time timeArr={doctorAvailability || []} />
        )}

        {/* GENDER */}
        {/* {!hideFollowUpInput(isFollowUp, Boolean(errors?.gender)) && <Gender />} */}
        {showGenderInput && <Gender />}

        {/* DATE OF BIRTH (DOB) */}
        {/* {!hideFollowUpInput(isFollowUp, Boolean(errors?.dob)) && <DOB />} */}
        {showDOBInput && <DOB />}

        {/* EMAIL */}
        {showEmailInput && <Email />}

        {/* PHONE NUMBER */}
        {showPhoneNumInput && <PhoneNumber />}

        {/* SYMPTOMS */}
        {!hideFollowUpInput(isFollowUp, Boolean(errors?.symptoms)) && (
          <Symptoms issues={issues || []} />
        )}

        {/* DISCOMFORT LEVEL */}
        {!hideFollowUpInput(isFollowUp, Boolean(errors?.discomfortLevel)) && (
          <DiscomfortLevel />
        )}

        {/* FIRST NOTICE */}
        {!hideFollowUpInput(isFollowUp, Boolean(errors?.firstNotice)) && (
          <FirstNotice />
        )}

        {/* CONTACT MEDIUM */}
        <ContactMedium />

        {/* ADDRESS */}
        {hasPharmacyFeature && <AddressInput />}

        {/* PHARMACY AND DELIVERY TYPE */}
        {hasPharmacyFeature && showPharmacySelectInput && (
          <PharmacyAndDeliverySelection
            loading={loadingPharmacies}
            pharmacyArray={pharmacyArray}
          />
        )}
        {loadingPharmacies && <Loading color={widgetColor} />}

        {/* ADDITIONAL INFORMATION */}
        <AdditionalInformation />

        <div className="btn-block">
          <p className=" !text-red-500 lg:w-[400px]">{conError}</p>
          <SubmitButton loading={loading} />
        </div>
      </div>
    </form>
  );
};
