import React from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { InputContainer } from "../../../InputContainer";
import { useAppState } from "../../../../contexts/appContext";

export const Select = ({
  name,
  isRequired = false,
  label,
  placeholder,
  options,
}) => {
  const appState = useAppState();
  const { widgetColor } = appState?.partnerInfo || {};

  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <InputContainer
      label={label}
      error={errors[name]?.message}
      required={isRequired}
    >
      <select
        className="select-input heela-discomfort-level"
        style={{ outlineColor: widgetColor }}
        {...register(name)}
      >
        {placeholder && <option value={undefined}>{placeholder}</option>}
        {options.map((option, idx) => {
          const isObject =
            option instanceof Object && option?.constructor === Object;
          if (isObject) {
            return (
              <option key={idx} value={option?.key}>
                {option?.value}
              </option>
            );
          }
          return <option key={idx}>{option}</option>;
        })}
      </select>
    </InputContainer>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.any,
      })
    ),
  ]).isRequired,
};
