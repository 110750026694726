import React from "react";
import { Input } from "./generic/Input";

export const Email = () => {
  return (
    <Input
      type="email"
      name="email"
      label="Email"
      placeholder="Enter Email"
      isRequired
    />
  );
};
