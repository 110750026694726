import React from "react";
import { Select } from "./generic/Select";

export const FirstNotice = () => {
  return (
    <Select
      name="firstNotice"
      label="When did you first notice the symptom(s)?"
      placeholder="Enter when you first noticed your symptoms"
      options={["A few days ago", "Last Week", "One Month ago", "Last Year"]}
      isRequired
    />
  );
};
