import React from "react";
import { Select } from "./generic/Select";

export const Time = ({timeArr}) => {
  return (
    <Select
      name="time"
      label="Select a time for your consultation"
      placeholder="Time"
      options={timeArr}
      isRequired
    />
  );
};
