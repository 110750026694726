import React from "react";
import classNames from "classnames";
import { Avatar, Rating } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";

export const DoctorCard = ({
  active = false,
  setActive,
  doctor,
  widgetColor,
}) => {
  const borderColor = active ? widgetColor : "#80808040";
  const name = `Dr. ${doctor?.firstName} ${doctor?.lastName}`;

  return (
    <div
      className={classNames(
        `flex items-center bg-white justify-between border rounded-md !px-5 !py-7 cursor-pointer !mx-1 transition ease-in-out duration-700`,
        { "border-2": active }
      )}
      style={{ borderColor }}
      onClick={() => setActive()}
    >
      <div className="flex items-center space-x-5">
        <Avatar src={doctor?.picture || ""} className="!border-2" />
        <div>
          <p className="!text-base">{name}</p>
          <p className="!text-xs">{doctor?.specialization}</p>
          <Rating
            size="small"
            defaultValue={doctor?.rating || 0}
            precision={0.1}
            readOnly
          />
        </div>
      </div>
      <ChevronRight />
    </div>
  );
};
