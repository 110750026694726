import React from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { InputContainer } from "../../../InputContainer";
import { useAppState } from "../../../../contexts/appContext";

export const Input = ({
  name,
  type,
  placeholder,
  label,
  isRequired = false,
}) => {
  const appState = useAppState();
  const { widgetColor } = appState?.partnerInfo || {};

  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <InputContainer
      label={label}
      error={errors[name]?.message}
      required={isRequired}
    >
      <input
        type={type}
        style={{ outlineColor: widgetColor }}
        placeholder={placeholder}
        {...register(name)}
      />
    </InputContainer>
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export const CustomInput = ({
  name,
  type,
  placeholder,
  label,
  isRequired = false,
  ...rest
}) => {
  const appState = useAppState();
  const { widgetColor } = appState?.partnerInfo || {};

  return (
    <InputContainer
      label={label}
      error=""
      required={isRequired}
    >
      <input
        type={type}
        style={{ outlineColor: widgetColor }}
        placeholder={placeholder}
        name={name}
        {...rest}
      />
    </InputContainer>
  );
};

CustomInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
};
