import React from "react";
import { useFormContext } from "react-hook-form";
import { InputContainer } from "../../InputContainer";
import { useAppState } from "../../../contexts/appContext";

export const ConsultationOwner = () => {
  const appState = useAppState();
  const { widgetColor } = appState?.partnerInfo || {};
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <InputContainer
      label="Who is this consultation for?"
      error={errors["consultationOwner"]?.message}
      required
    >
      <select
        className="select-input"
        style={{ outlineColor: widgetColor }}
        {...register("consultationOwner")}
        onChange={(event) => {
            const value = event?.target?.value;
            ["hmoId", "principalHmoId"].map((item) => setValue(item, undefined));
            setValue("consultationOwner", value);
        }}
      >
        <option value="">Select an Owner</option>
        <option value="Myself">Myself</option>
        <option value="Dependant">Dependant</option>
      </select>
    </InputContainer>
  );
};
