import { appReducerTypes } from "../utils/mockData";

export const appReducer = (state, action) => {
  switch (action.type) {
    case appReducerTypes.ADD_KEY:
      return {
        ...state,
        key: action.payload.key,
      };

    case appReducerTypes.ADD_PLANS:
      return {
        ...state,
        plans: action.payload.plans,
      };

    case appReducerTypes.CHANGE_FORM_STEP:
      return {
        ...state,
        step: action.payload.step,
      };

    case appReducerTypes.CHANGE_LOADING_STATE:
      return {
        ...state,
        loading: action?.payload?.loading,
      };

    case appReducerTypes.ADD_PARTNER_INFO:
      return {
        ...state,
        partnerInfo: action.payload.partnerInfo,
      };

    case appReducerTypes.ADD_PROVIDER_FEATURES:
      return {
        ...state,
        providerFeatures: action.payload.providerFeatures,
      };

      case appReducerTypes.UPDATE_IS_HOSPITAL_CONSULTATION:
      return {
        ...state,
        isHospitalConsultation: action.payload.isHospitalConsultation,
      };
      
    default:
      break;
  }
};
