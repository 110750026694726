import React, { createRef } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/react";
import { createClient } from "graphql-ws";
import "react-calendar/dist/Calendar.css";
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  split,
  ApolloLink,
  concat,
} from "@apollo/client";

Sentry.init({
  dsn: "https://920413ab37a448ea8a61f5f27fdc3e39@o4505028684808192.ingest.sentry.io/4505029066948608",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const wsLink = new GraphQLWsLink(
  createClient({
    //eslint-disable-next-line
    url: `${apiBaseUrl}`,
  })
);

const httpLink = new HttpLink({
  uri: apiBaseUrl,
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const authMiddleware = new ApolloLink((operation, forward) => {
  const accessToken = sessionStorage.getItem("access_token");
  operation.setContext((context) => ({
    headers: {
      ...context.headers,
      authorization: accessToken ? `Bearer ${accessToken}` : null,
      "Access-Control-Allow-Credentials": true,
      "Content-Type": "application/json",
    },
  }));

  return forward(operation);
});

const client = new ApolloClient({
  connectToDevTools: true,
  cache: new InMemoryCache(),
  link: concat(authMiddleware, splitLink),
  resolvers: {},
});
// add action to all snackbars
const notistackRef = createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={3}
      action={(key) => (
        <button
          onClick={onClickDismiss(key)}
          className="text-white font-semibold text-sm"
          style={{
            cursor: "pointer",
          }}
        >
          Dismiss
        </button>
      )}
    >
      <BrowserRouter>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </BrowserRouter>
    </SnackbarProvider>
  </React.StrictMode>
);
