import React from "react";
import PropTypes from "prop-types";
import { ErrorSvg } from "../svgs/ErrorSvg";

export const NoData = ({
  widgetColor,
  message,
  info,
  handleClick,
  btnText,
}) => {
  return (
    <div className="flex flex-col items-center">
      <ErrorSvg color={widgetColor} />
      <h4 className="text-[#2c2c2c] text-center !text-xl font-medium !mb-1">
        {message}
      </h4>
      <p className="text-center !text-sm">{info}</p>
      {btnText && handleClick && (
        <button
          type="button"
          style={{ backgroundColor: widgetColor }}
          className=" cursor-pointer !mt-5"
          onClick={() => {
            handleClick();
          }}
        >
          {btnText}
        </button>
      )}
    </div>
  );
};

NoData.propTypes = {
  widgetColor: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  info: PropTypes.string,
  handleClick: PropTypes.func,
  btnText: PropTypes.string,
};
