import { createContext, useContext, useEffect, useReducer } from "react";
import { format } from "date-fns";
import { useFormContext } from "react-hook-form";
import { updateFormValues } from "../utils/funcs";
import { formReducer } from "../reducers/formReducer";

const FormContext = createContext(null);

const storedState = JSON.parse(sessionStorage.getItem("formState"));
const initialState = storedState || {
  userData: undefined,
  selectedPrevConsultation: undefined,
  selectedHospital: undefined,
  selectedDoctor: undefined,
  selectedDate: format(Date.now(), "yyyy-MM-dd"),
};

export const FormContextProvider = ({ children }) => {
  const { setValue } = useFormContext();
  const [formState, dispatch] = useReducer(formReducer, initialState);

  useEffect(() => {
    updateFormValues(setValue, formState);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState?.userData]);

  return (
    <FormContext.Provider value={{ formState, dispatch }}>
      {children}
    </FormContext.Provider>
  );
};

export const useFormState = () => {
    const context = useContext(FormContext);
    return context?.formState || {}
}

export const useFormDispatch = () => {
    const context = useContext(FormContext);
    return context?.dispatch;
}
