import { useState } from "react";
import { FormButton } from "../buttons";
import { useFormContext } from "react-hook-form";
import { EnrolleeId } from "./inputs/EnrolleeId";
// import { useMixPanel } from "../../hooks/useMixPanel";
import { useAppState } from "../../contexts/appContext";
import { getNextStepFromIntroForm } from "../../utils/funcs";
import { ConsultationType } from "./inputs/ConsultationType";
import { ConsultationOwner } from "./inputs/ConsultationOwner";

export const IntroForm = () => {
  const appState = useAppState();
  // const { loadCreateConsultationPage } = useMixPanel();
  const hasFollowUpFeature = (appState || {})?.providerFeatures
    ?.hasFollowUpFeature;

  const [foundUser, setFoundUser] = useState(false);

  const { getValues } = useFormContext();
  const isHospitalConsultation = appState?.isHospitalConsultation;
  const nextStep = getNextStepFromIntroForm(
    getValues()?.isFollowUp,
    isHospitalConsultation
  );

  const canContinue = foundUser;
  const canSelectNewStep =
    hasFollowUpFeature && foundUser && !isHospitalConsultation;

  const consultationOwner = getValues()?.consultationOwner;
  const showEnrolleeIdInput = consultationOwner && consultationOwner !== "";
  const showPrincipalInput =
    consultationOwner === "Dependant" &&
    getValues()?.hmoId &&
    getValues()?.hmoId !== "";
  const principalIdNotEmpty =
    getValues()?.principalHmoId !== "" && getValues()?.principalHmoId;
  const isDependentConsultation = showPrincipalInput
    ? principalIdNotEmpty
    : true;

  // useEffect(() => {
  //   loadCreateConsultationPage();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <form className="form-container">
      {/* Select Consultation Owner */}
      <ConsultationOwner />

      {/* HMO ID */}
      {showEnrolleeIdInput && (
        <EnrolleeId
          name="hmoId"
          queryKey="user"
          setFoundUser={(found) => setFoundUser(found)}
        />
      )}

      {/* PRINCIPAL HMO ID */}
      {showPrincipalInput && (
        <EnrolleeId
          name="principalHmoId"
          queryKey="principalUser"
          setFoundUser={() => null}
        />
      )}

      {canContinue && isDependentConsultation && (
        <div className="space-y-3">
          {/* TYPE OF CONSULTATION */}
          {canSelectNewStep && <ConsultationType setNextStep={() => null} />}

          <FormButton nextStep={nextStep} />
        </div>
      )}
    </form>
  );
};
