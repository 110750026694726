import React from "react";
import classNames from "classnames";
import { Avatar } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";

export const HospitalCard = ({
  active = false,
  setActive,
  hospital,
  widgetColor,
}) => {

    const borderColor = active ? widgetColor : "#80808040";

  return (
    <div
    style={{ borderColor }}
      onClick={setActive}
      className={classNames(
        "flex items-center justify-between !bg-white border rounded-lg space-x-7 !px-5 !py-7 cursor-pointer",
        { "border-2": active }
      )}
    >
      <div className="flex items-center space-x-5">
        <Avatar src={hospital?.icon || ""} className="!border-2" />
        <p className="!text-base">{hospital?.name || "No name"}</p>
      </div>
      <ChevronRight />
    </div>
  );
};
