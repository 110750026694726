import { formReducerTypes } from "../utils/mockData";

export const formReducer = (state, action) => {
  switch (action.type) {
    case formReducerTypes.ADD_USER_DATA: {
      const newState = {
        ...state,
        userData: action?.payload?.userData,
      };
      sessionStorage.setItem("formState", JSON.stringify(newState));

      return newState;
    }

    case formReducerTypes.ADD_SEL_PREV_CONSULT: {
      const newState = {
        ...state,
        selectedPrevConsultation: action?.payload?.selected,
      };
      sessionStorage.setItem("formState", JSON.stringify(newState));

      return newState;
    }

    case formReducerTypes.ADD_SELECTED_HOSPITAL: {
      const newState = {
        ...state,
        selectedHospital: action?.payload?.hospital,
      };
      sessionStorage.setItem("formState", JSON.stringify(newState));

      return newState;
    }

    case formReducerTypes.ADD_SELECTED_DOCTOR: {
      const newState = {
        ...state,
        selectedDoctor: action?.payload?.doctor,
      };
      sessionStorage.setItem("formState", JSON.stringify(newState));

      return newState;
    }

    case formReducerTypes.ADD_SELECTED_DATE: {
      const newState = {
        ...state,
        selectedDate: action?.payload?.date,
      };
      sessionStorage.setItem("formState", JSON.stringify(newState));

      return newState;
    }

    default:
      break;
  }
};
