import React from "react";

export const displayLogo = (id, widgetLogo) => {
  const logoUrl =
    id || widgetLogo
      ? widgetLogo
      : "https://heala-partners-2qzf8yrm7-heala-io.vercel.app/static/media/logo.723375eb.svg";
  const altText = id || widgetLogo ? "Partner Logo" : "Heala logo";

  return <img src={logoUrl} alt={altText} srcSet="" className="partner-logo" />;
};
