import React from "react";
import PropTypes from "prop-types";
import { Grid, CircularProgress } from "@mui/material";

export const Loading = ({ color = "primary", size = 20, ...rest }) => {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <CircularProgress
        size={size}
        sx={{color}}
        {...rest}
      />
    </Grid>
  );
};
Loading.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};