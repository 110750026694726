import React, { useEffect, useState } from "react";
import { Loading } from "../Loading";
import { BackButton } from "../buttons";
import { NoData } from "../cards/NoData";
import { useLazyQuery } from "@apollo/client";
import { HospitalCard } from "../cards/HospitalCard";
import { getHospitals } from "../../api/graphQL/query";
import { useAppDispatch, useAppState } from "../../contexts/appContext";
import { useFormDispatch, useFormState } from "../../contexts/formContext";
import {
  appReducerTypes,
  formReducerTypes,
  hospitalSteps,
} from "../../utils/mockData";

export const SelectHospital = () => {
  const appState = useAppState();
  const dispatch = useAppDispatch();
  const formState = useFormState();
  const formDispatch = useFormDispatch();
  const [value, setValue] = useState("");
  const { widgetColor } = appState?.partnerInfo || {};
  const [fetchHospitals, { data, error, loading }] = useLazyQuery(
    getHospitals,
    { variables: { hospitalId: "61ed2354e6091400135e3d94" } }
  );

  const hospitals = data?.getUserTypeProviders?.provider || [];

  useEffect(() => {
    fetchHospitals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <form className="max-w-[570px]">
        <BackButton step={hospitalSteps.INTRO_FORM} />
        <div>
          {loading ? (
            <Loading color={widgetColor} size={16} />
          ) : error ? (
            <NoData
              widgetColor={widgetColor}
              message="Error"
              info={error?.message}
              btnText="Reload"
              handleClick={() => {
                fetchHospitals();
              }}
            />
          ) : (hospitals || []).length > 0 ? (
            <div className="space-y-7">
              <div>
                <h4 className="text-xl text-gray-400">Hospitals</h4>
                <p>Select a Hospital to Continue</p>
              </div>
              <div className="space-y-5 !mb-5">
                <div className="flex items-center !px-1 space-x-4">
                  <input
                    type="text"
                    disabled={loading}
                    placeholder="Search for hospital"
                    className="block"
                    value={value}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      if (newValue === "") fetchHospitals();
                      setValue(newValue);
                    }}
                    style={{ outlineColor: widgetColor }}
                  />
                  <button
                    disabled={loading}
                    className="flex-1 !text-xs !px-3 !h-[40px]"
                    onClick={() =>
                      fetchHospitals({ variables: { name: value } })
                    }
                  >
                    Search
                  </button>
                </div>
                {(hospitals || []).map((hospital, idx) => {
                  const selectedHospitalId = formState?.selectedHospital?._id;
                  const isActive = selectedHospitalId === hospital?._id;

                  return (
                    <HospitalCard
                      key={`${idx}-${hospital?._id}`}
                      active={isActive}
                      setActive={() => {
                        formDispatch({
                          type: formReducerTypes.ADD_SELECTED_HOSPITAL,
                          payload: { hospital: hospital },
                        });
                        dispatch({
                          type: appReducerTypes?.CHANGE_FORM_STEP,
                          payload: {
                            step: hospitalSteps.SELECT_HOSPITAL_DOCTOR,
                          },
                        });
                      }}
                      hospital={hospital}
                      widgetColor={widgetColor}
                    />
                  );
                })}
              </div>
            </div>
          ) : (
            <NoData
              widgetColor={widgetColor}
              message="No Hospitals"
              info=""
              btnText="Reload"
              handleClick={() => {
                fetchHospitals();
              }}
            />
          )}
        </div>
      </form>
    </div>
  );
};
