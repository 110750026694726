import React from "react";
import { Input } from "./generic/Input";

export const PhoneNumber = () => {
  return (
    <Input
      type="tel"
      name="phoneNumber"
      label="Phone number"
      placeholder="Enter Phone Number"
      isRequired
    />
  );
};
