import React from "react";
import { getStyles } from "../utils/funcs";
import { healaLogo } from "../utils/mockData";
import { useAppState } from "../contexts/appContext";

export const FormContainer = ({ children }) => {
    const appState = useAppState();
    const { widgetColor, widgetLogo, subdomain, key } =
    appState?.partnerInfo || {};
    const styles = getStyles(widgetColor);
    const logo = widgetLogo ? widgetLogo : healaLogo;

    return (
        <div className="heala-root-form-container">
          <div>
            <div style={Object.assign({}, styles.bodyBG)} className="conBG">
              <div style={Object.assign({}, styles.before)} className="conBG" />
              <div style={Object.assign({}, styles.children)}>
                <div className="md:min-w-[452px] main-block">
                  <div className="heela-form-title-container">
                    <img
                      src={logo}
                      className="heela-form-title-logo"
                      alt={subdomain ? subdomain : "Heala logo"}
                    />
                  </div>
                  <div className="heela-form-controller">
                    <h3> Consult a Doctor</h3>
                    <p className="heela-form-description">
                      Fill the form below and we will connect you to a Doctor
                    </p>
                    {/* FORMS */}
                    <div className="!mt-4">{children}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {key ? (
            <div
              style={{
                padding: "5%",
                marginBottom: "8%",
                position: "absolute",
                bottom: "0",
                left: " 20%",
              }}
              className="flex flex-col items-center .items-center_fix"
            >
              <div className="flex justify-center items-center space-x-1">
                <p
                  className="leading-5 m-0 "
                  style={{ fontSize: "18px", color: "black" }}
                >
                  powered by
                </p>
                <img src={logo} alt="heala logo" />
              </div>
            </div>
         ) : (
           ""
          )}
        </div>
    )
}