import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
     status: {
          danger: "#e53e3e",
     },
     palette: {
          primary: {
               main: "#3E5EA9",
               darker: "#053e85",
          },
          neutral: {
               main: "#64748B",
               contrastText: "#fff",
          },
     },
});
