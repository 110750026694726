import React, { createContext, useContext, useEffect, useReducer } from "react";
import { initialAppState } from "../utils/mockData";
import { appReducer } from "../reducers/appReducer";

const AppContext = createContext(null);

const storageState = JSON.parse(sessionStorage.getItem("appState"));

const initialState = storageState || initialAppState;

export const AppContextProvider = ({ children }) => {
  const [appState, dispatch] = useReducer(appReducer, initialState);

  useEffect(() => {
    sessionStorage.setItem("appState", JSON.stringify(appState));
  }, [appState])
  return (
    <AppContext.Provider value={{ appState, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppState = () => {
    const context = useContext(AppContext);
    return context?.appState || {}
}

export const useAppDispatch = () => {
    const context = useContext(AppContext);
    return context?.dispatch;
}
